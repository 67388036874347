import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import _ from 'lodash';
import lcapPageIcon from '@icons/resource/lcap_page.svg';

import {RequiredProps} from '@shared/types/lib';
import {HttpResource, Request} from '@shared/services/http-resource';
import {LcapPageType} from '@shared/modules/lcap-page/lcap-page.types';

import {Folder, Project} from '../../modules/folders/folders.types';
import {LcapPageAsset} from '../../pages/assets-page/assets.types';
import {RootDialogService} from '../root-dialog.service';
import {RedirectedResponse} from '../../types';
import {makeRedirectedRequest} from '../../utils/make-redirected-request';

import {LcapDefaultPage, LcapDefaultPageResponse, WorkflowStage} from './types';
import {LcapAppTab} from './lcap-tabs.service';

type CreatePageParams = Pick<LcapDefaultPage, 'name' | 'path' | 'folder_id' | 'content'> & {
  workflow_stage_id?: WorkflowStage['id'] | null;
  tab_id?: LcapAppTab['id'] | null;
};

export interface LcapPageInfo {
  id: LcapDefaultPage['id'];
  name: LcapDefaultPage['name'];
  type: LcapPageType;
}

export interface LcapPageOption {
  title: LcapDefaultPage['name'];
  value: LcapDefaultPage['id'];
}

@Injectable({providedIn: 'root'})
export class LcapPagesService {
  resource: HttpResource;

  constructor(
    private http: HttpClient,
    private dialog: RootDialogService,
  ) {
    this.resource = new HttpResource(this.http, {
      url: '/web_api/lcap/{{source}}/{{sourceId}}/pages/{{id}}/{{action}}.json',
    });
  }

  moveToFolder(appPageId: LcapDefaultPage['id'], folderId: Folder['id']): Promise<void> {
    return this.resource.put({id: appPageId}, {page: {folder_id: folderId}});
  }

  createPage(params: CreatePageParams): Promise<LcapDefaultPage> {
    return this.resource.create({page: params});
  }

  deletePage(appPageId: LcapDefaultPage['id']): Promise<void> {
    return this.resource.delete({id: appPageId});
  }

  getPage(appPageId: LcapDefaultPage['id']): Request<LcapDefaultPageResponse> {
    return this.resource.get({id: appPageId});
  }

  updatePage(appPage: RequiredProps<Partial<LcapDefaultPage>, 'id'>): Promise<void> {
    return this.resource.update({id: appPage.id}, {page: appPage});
  }

  getProjectPages(projectId: Project['id']): Promise<LcapPageInfo[]> {
    return this.resource.get({source: 'projects', sourceId: projectId});
  }

  async clonePage(id: number): Promise<RedirectedResponse> {
    return makeRedirectedRequest(this.http.post(this.resource.url({id, action: 'clone'}), {}, {observe: 'response'}));
  }

  async showPageDeleteConfirmation(appPage: Omit<LcapPageAsset, 'asset_type'>): Promise<boolean> {
    return this.dialog.openConfirmationDialog(
      'Permanently delete page?',
      `Deleting <b>${_.escape(
        appPage.name,
      )}</b>.<br/>Pages can’t be recovered from Trash, so this action can’t be undone.`,
      {
        width: 624,
        confirmButton: 'Delete page',
        cancelButton: 'Cancel',
        headerIcon: lcapPageIcon.id,
        theme: 'negative',
      },
    );
  }

  convertPageToOption({id, name}: LcapPageInfo): LcapPageOption {
    return {title: name, value: id};
  }
}
