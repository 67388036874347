import {ErrorHandler, Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';

import {RecipeId} from '../types';

import {TabsCommunicationService} from './tabs-communication/tabs-communication.service';
import {BroadcastChannelAdapter} from './tabs-communication/broadcast-channel-adapters/broadcast-channel-adapter';
import {AuthUser} from './auth-user';

type RecipeChangeType =
  | 'created'
  | 'updated'
  | 'deleted'
  | 'restored'
  | 'copied'
  | 'folder-changed'
  | 'version-changed'
  | 'imported'
  | 'started'
  | 'stopped';

type RecipeChangeEventOf<
  TType extends RecipeChangeType = RecipeChangeType,
  TData extends object | void = void,
  THasId extends boolean = true,
> = {
  type: TType;
} & (TData extends object ? {data: TData} : {}) &
  (THasId extends true ? {recipeId: RecipeId} : {});

type RecipeCreatedEvent = RecipeChangeEventOf<'created'>;
type RecipeUpdatedEvent = RecipeChangeEventOf<'updated'>;
type RecipeDeletedEvent = RecipeChangeEventOf<'deleted'>; // Recipe moved to Trash (from assets page or recipe page)
type RecipeRestoredEvent = RecipeChangeEventOf<'restored', {ids: RecipeId[]}, false>; // Restored from Trash
type RecipeCopiedEvent = RecipeChangeEventOf<'copied'>;
type RecipeFolderChangedEvent = RecipeChangeEventOf<'folder-changed'>;
type RecipeVersionChangedEvent = RecipeChangeEventOf<'version-changed'>;
type RecipeImportedEvent = RecipeChangeEventOf<'imported', void, false>;
type RecipeStartedEvent = RecipeChangeEventOf<'started'>;
type RecipeStoppedEvent = RecipeChangeEventOf<'stopped'>;

type RecipeEvent =
  | RecipeCreatedEvent
  | RecipeUpdatedEvent
  | RecipeDeletedEvent
  | RecipeRestoredEvent
  | RecipeCopiedEvent
  | RecipeFolderChangedEvent
  | RecipeVersionChangedEvent
  | RecipeImportedEvent
  | RecipeStartedEvent
  | RecipeStoppedEvent;

export type RecipeChangeEvent<TType extends RecipeChangeType = RecipeChangeType> = Extract<RecipeEvent, {type: TType}>;

const CHANNEL_ID = 'recipesTracking';

@Injectable({providedIn: 'root'})
export class RecipesTrackingService implements OnDestroy {
  change$: Observable<RecipeChangeEvent>;

  private changeSubject = new Subject<RecipeChangeEvent>();

  private readonly channelAdapter: BroadcastChannelAdapter<RecipeChangeEvent>;

  constructor(
    authUser: AuthUser,
    private tabsCommunicationService: TabsCommunicationService,
    private errorHandler: ErrorHandler,
  ) {
    this.change$ = this.changeSubject.asObservable();
    this.channelAdapter = this.tabsCommunicationService.createChannel(`${CHANNEL_ID}.${authUser.id}`);
    this.channelAdapter.setMessageHandler(this.messageHandler);
  }

  ngOnDestroy() {
    this.channelAdapter.close();
  }

  notify(event: RecipeChangeEvent) {
    try {
      this.channelAdapter.postMessage(event);
    } catch (error) {
      this.errorHandler.handleError(error);
    }
  }

  private messageHandler = (event: RecipeChangeEvent): void => {
    this.changeSubject.next(event);
  };
}
