import _ from 'lodash';
import {Injectable} from '@angular/core';
import connectionIcon from '@icons/resource/connection.svg';
import {toWordsOrdinal} from 'number-to-words';

import {pluralize} from '@shared/pipes/plural.pipe';
import {MixpanelEvent, MixpanelEventParams, MixpanelService} from '@shared/services/mixpanel';

import {AuthUser} from '../../services/auth-user';
import {ConnectionAsset} from '../assets-page/assets.types';
import {RootDialogService} from '../../services/root-dialog.service';
import {Adapter, Hash, Picklist, PicklistItem, SchemaField} from '../../types';

import {Connection, ConnectionStatus, ConnectionsMixpanelEvents, ExtendedConnection} from './connections.types';

@Injectable({
  providedIn: 'root',
})
export class ConnectionHelper {
  constructor(
    private authUser: AuthUser,
    private dialog: RootDialogService,
    private mixpanelService: MixpanelService,
  ) {}

  isConnected(connection: Pick<Connection, 'authorization_status'>): boolean {
    return connection.authorization_status === 'success';
  }

  isConnectionLost(connection: Pick<Connection, 'connection_lost_at'>): boolean {
    return Boolean(connection.connection_lost_at);
  }

  isExtendedConnection(connection: Connection | ExtendedConnection): connection is ExtendedConnection {
    return typeof (connection as ExtendedConnection).input === 'object';
  }

  isCurrentlyUsed(
    connection: Pick<
      Connection,
      'alr_connection' | 'authorization_status' | 'proxy_api_endpoints_count' | 'secrets_manager_connection'
    >,
  ): boolean {
    return (
      this.isConnected(connection) &&
      (connection.alr_connection || connection.proxy_api_endpoints_count > 0 || connection.secrets_manager_connection)
    );
  }

  canDeleteConnection(
    connection: Pick<
      Connection,
      'alr_connection' | 'authorization_status' | 'proxy_api_endpoints_count' | 'secrets_manager_connection'
    >,
  ): boolean {
    return !this.isCurrentlyUsed(connection);
  }

  getDeleteMessage(connection: Pick<Connection, 'alr_connection' | 'proxy_api_endpoints_count'>): string | null {
    if (connection.alr_connection) {
      return 'You can’t delete a connection used by audit log streaming';
    } else if (connection.proxy_api_endpoints_count > 0) {
      return 'You can’t delete a connection used by proxy endpoints';
    }

    return null;
  }

  getConnectionIdentity(connection?: Connection): Connection['identity'] | undefined {
    return this.authUser.oem_customer_with_generated_email && connection?.provider === 'workato_app'
      ? this.authUser.oem_generated_email_replacement
      : connection?.identity;
  }

  getDependencyGraphUrl(connection: Connection): string {
    return `/dashboard/assets?assetType=connection&assetId=${connection.id}`;
  }

  async showDeleteConfirmation(connection: Omit<ConnectionAsset, 'asset_type'>): Promise<boolean> {
    if (!connection.recipe_count) {
      return true;
    }

    const recipeCount = pluralize(connection.recipe_count, 'recipe');

    return this.dialog.openConfirmationDialog(
      'Permanently delete connection?',
      `Deleting <b>${_.escape(connection.name)}</b> will impact <b>${recipeCount}</b> using it.<br/>
  Connections can’t be recovered from Trash, so this action can’t be undone.`,
      {
        width: 624,
        confirmButton: 'Delete connection',
        cancelButton: 'Cancel',
        headerIcon: connectionIcon.id,
        theme: 'negative',
      },
    );
  }

  generateName(adapter: Adapter, existingConnections: Connection[]): string {
    let counter = existingConnections.length;
    let name;

    do {
      counter++;
      name = `My${counter === 1 ? '' : ` ${toWordsOrdinal(counter)}`} ${adapter.title} account`;
    } while (this.getNameValidationError(name, existingConnections));

    return name;
  }

  getNameValidationError(name: string, existingConnections: Connection[], id?: Connection['id']): string {
    if (this.isEmptyName(name)) {
      return "Name can't be blank";
    }

    if (!this.isUniqueName(id, name, existingConnections)) {
      return 'Name already exists';
    }

    return '';
  }

  isEmptyName(name: string): boolean {
    return !name.trim();
  }

  isUniqueName(id: Connection['id'] | undefined, name: Connection['name'], existingConnections: Connection[]): boolean {
    return existingConnections.every(connection => connection.name !== name || connection.id === id);
  }

  sendMixpanelEventForAction(
    action: string,
    provider: Connection['provider'],
    mixpanelEvents: ConnectionsMixpanelEvents | undefined,
  ) {
    const mixpanelEventInfo = _.get(mixpanelEvents, action);

    if (!mixpanelEventInfo) {
      return;
    }

    let params: MixpanelEventParams = {};
    let event: MixpanelEvent;

    if (Array.isArray(mixpanelEventInfo)) {
      event = mixpanelEventInfo[0];
      params = mixpanelEventInfo[1];
    } else {
      event = mixpanelEventInfo;
    }

    this.mixpanelService.track(event, {...params, provider});
  }

  updateUseManagedConnectionField(shouldShowOnPremSelect: boolean, connection: Connection) {
    if (shouldShowOnPremSelect) {
      const selectedGroupId = Number(connection.secure_gateway_tunnel_id);

      connection.use_managed_connection =
        !selectedGroupId || Boolean(this.authUser.secure_gateway_tunnels.find(([, id]) => id === selectedGroupId)?.[2]);
    } else {
      delete connection.use_managed_connection;
    }
  }

  generateCustomOauthField(connection: Connection): SchemaField {
    const profileHintPart = connection.custom_oauth_key_id
      ? `You can update selected profile <a href="/custom_oauth_keys/${connection.custom_oauth_key_id}" target="_blank">here</a>.`
      : '';
    const options: Picklist = this.authUser.custom_oauth_keys
      .filter(key => key.provider === connection.provider)
      .map(key => [key.name, key.id]);

    return {
      name: 'custom_oauth_key_id',
      control_type: 'select',
      label: 'Custom OAuth profile',
      placeholder: 'Select OAuth profile',
      optional: true,
      options,
      hint: `
        When selected, all requests to the app will use the profile specified
        <a href="/custom_oauth_keys/" target="_blank">here</a>.
        ${profileHintPart}
        Create new profile
        <a href="/custom_oauth_keys/new?provider=${connection.provider}" target="_blank">here</a>.
      `,
    };
  }

  generateSecureAgentField(hasAdapter: boolean, secureTunnelRequired: boolean): SchemaField | null {
    if (!hasAdapter) {
      return null;
    }

    const label = secureTunnelRequired ? 'On-prem group' : 'Connection type';
    const secureGroupsOptions: PicklistItem[] = this.authUser.secure_gateway_tunnels.map(([name, id]) => [name, id]);
    const hintLink = '<a href="https://docs.workato.com/on-prem/agents/connection.html" target="_blank">Learn more</a>';
    const hint = secureTunnelRequired
      ? `You’ll use agents in this on-prem group to connect your application. ${hintLink}`
      : `If you want to connect using an on-prem group, please choose one from the dropdown. ${hintLink}`;

    return {
      name: 'secure_gateway_tunnel_id',
      control_type: 'select',
      label,
      optional: false,
      options: secureTunnelRequired ? secureGroupsOptions : [['Cloud', ''], ...secureGroupsOptions],
      hint,
      default: secureTunnelRequired ? '' : 'Cloud',
      placeholder: 'Choose on-prem group',
    };
  }

  getErrorStatus(message = 'Server error', errors?: Hash<string[]>): ConnectionStatus {
    if (errors && _.isPlainObject(errors)) {
      const name = Object.keys(errors)[0];

      message = `${name} ${errors[name][0]}`;
    }

    return {
      success: false,
      message,
    };
  }

  getDisconnectDescription(connection: Connection): string {
    const parts: string[] = [];

    if (connection.secrets_manager_connection) {
      parts.push('usage of your stored secrets in your connections');
    }

    if (connection.alr_connection) {
      parts.push('streaming of your audit log data');
    }

    if (this.isExtendedConnection(connection) && connection.running_recipe_count > 0) {
      parts.push(
        `<b>${connection.running_recipe_count}</b> ${pluralize(
          connection.running_recipe_count,
          'active recipe',
          true,
        )}`,
      );
    }

    if (connection.proxy_api_endpoints_count > 0) {
      parts.push(
        `<b>${connection.proxy_api_endpoints_count}</b> ${pluralize(
          connection.proxy_api_endpoints_count,
          'API proxy endpoint',
          true,
        )}`,
      );
    }

    if (!parts.length) {
      return '';
    }

    // join parts ['a', 'b', 'c', 'd'] to get 'a, b, c and d' string.
    const result = parts.length > 1 ? `${parts.slice(0, -1).join(', ')} and ${parts[parts.length - 1]}` : parts[0];

    return `${result.charAt(0).toUpperCase()}${result.slice(1)} will be affected.`;
  }
}
