<div @fadeInOut class="team-switcher" [wKeyboardNavigation]="{cyclic: true, autofocus: true}">
  <button class="team-switcher__back" (click)="close.emit()">
    <w-svg-icon class="team-switcher__back-icon" iconId="@shared/chevron-left" />
    Menu
  </button>
  <h3 class="team-switcher__current-team">{{ currentTeamName }}</h3>

  <button
    class="team-switcher__close"
    (click)="hideSidebar.emit()"
    wIconButton="@shared/close"
    wIconButtonAriaLabel="Close sidebar"
  ></button>

  <ng-container *ngIf="hasEnvironments">
    <w-env-switcher
      class="team-switcher__env-switcher"
      [currentEnvironment]="currentEnvironment"
      [availableEnvironments]="availableEnvironments"
    />
  </ng-container>

  <ng-container *ngIf="hasTeams">
    <hr class="team-switcher__separator" />
    <h4 class="team-switcher__header">Switch workspace</h4>

    <ng-container *ngIf="hasSearch">
      <div class="team-switcher__search">
        <input
          class="team-switcher__search-input"
          type="text"
          placeholder="Search"
          [(ngModel)]="searchQuery"
          (input)="filterTeams()"
          wKeyboardNavigationItem
        />
        <w-svg-icon class="team-switcher__search-icon" iconId="@shared/search" />
        <button
          *ngIf="searchQuery"
          class="team-switcher__search-clear"
          type="button"
          (click)="clearSearchQuery()"
          wIconButton="@shared/close"
          wIconButtonAriaLabel="Clear search"
          [wIconButtonIconSize]="8"
        ></button>
      </div>
    </ng-container>

    <div class="team-switcher__content">
      <ng-container *ngIf="!filteredTeams; else filteredTeamsTmpl">
        <w-team-item *ngIf="loggedUserTeam" [team]="loggedUserTeam" (onSelect)="handleSelectTeam(loggedUserTeam)" />

        <w-teams-group
          *ngIf="hasLastAccessedGroup"
          label="Last accessed"
          [teams]="lastAccessedTeams"
          (onTeamSelect)="handleSelectTeam($event)"
        />

        <w-teams-group
          *ngIf="teams.length"
          [label]="loggedUserTeam || hasLastAccessedGroup ? 'Other workspaces' : null"
          [teams]="teams"
          (onTeamSelect)="handleSelectTeam($event)"
        />
      </ng-container>

      <ng-template #filteredTeamsTmpl>
        <ng-container *ngIf="filteredTeams!.length; else emptySearchTmpl">
          <w-teams-group
            label="{{ filteredTeams!.length | wPlural: 'workspace' }} found"
            [searchQuery]="searchQuery"
            [teams]="filteredTeams!"
            (onTeamSelect)="handleSelectTeam($event)"
          />
        </ng-container>

        <ng-template #emptySearchTmpl>
          <div class="team-switcher__empty">
            <div class="team-switcher__empty-title">No matches found</div>
            Try changing the search terms
          </div>
        </ng-template>
      </ng-template>
    </div>
  </ng-container>
</div>
