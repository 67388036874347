import {Directive, ElementRef, HostBinding, OnChanges} from '@angular/core';

export const OVERLAY_EXTRA_SPACE_TRANSITION_MS = TEST ? 0 : 100;

@Directive({selector: '[wOverlayExtraSpace]', standalone: true})
export class OverlayExtraSpaceDirective implements OnChanges {
  @HostBinding('style.transition') transition =
    `padding-bottom cubic-bezier(0.34, 1.56, 0.64, 1) ${OVERLAY_EXTRA_SPACE_TRANSITION_MS}ms`;

  @HostBinding('style.padding-bottom') paddingBottom?: string;

  private extraSpaceDelta = 0;

  constructor(public elementRef: ElementRef<Element>) {}

  ngOnChanges() {
    this.calculateStyle();
  }

  get isActive(): boolean {
    return Boolean(this.extraSpaceDelta);
  }

  setRequiredDelta(value: number) {
    this.extraSpaceDelta = value;
    this.calculateStyle();
  }

  private calculateStyle() {
    let value: string | undefined;

    if (this.extraSpaceDelta) {
      value = `${this.extraSpaceDelta}px`;
    }

    this.paddingBottom = value;
  }
}
