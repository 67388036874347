import {Injectable, OnDestroy, inject} from '@angular/core';
import {Hotkey, HotkeysService as NgHotkeysService} from 'angular2-hotkeys';
import {difference, isNumber} from 'lodash';
import {Subscription} from 'rxjs';
import {filter, take} from 'rxjs/operators';

import {MixpanelService} from './mixpanel';

// Return value `true` will not prevent default behaviour
type HotkeyCallback = (event: KeyboardEvent) => void | boolean;

interface HotkeyOptions {
  description?: string;
  /*
   * By default hotkeys doesn't work in `input`, `select` and `textarea` elements.
   * This option allows to allow hotkeys in some of them.
   */
  allowIn?: Array<'input' | 'select' | 'textarea'>;
  // If explicitly set to `false`, the hotkey won't be affected by removeAll/toggleAll calls
  removable?: boolean;
  // Defines position in the cheatsheet list, defaults to 0 (lowest position)
  priority?: number;
}

export interface HotkeyManager {
  toggle(flag: boolean): void;
}

export const IS_MAC_OS = navigator.platform.toUpperCase().includes('MAC');

export const alt = IS_MAC_OS ? 'option' : 'alt';
export const cmdOrCtrl = IS_MAC_OS ? '⌘' : 'Ctrl';

export const HOTKEY_MAP = {
  skip: {
    hotkey: 'mod+/',
    label: IS_MAC_OS ? '⌘ /' : 'Ctrl /',
    description: 'Skip/unskip recipe step',
  },
  comment: {
    hotkey: `mod+${alt}+a`,
    label: IS_MAC_OS ? '⌘ Opt A' : 'Ctrl Alt A',
    description: 'Add comment to recipe step',
  },
  zoom_in: {
    hotkey: [`mod+${alt}+plus`, `mod+${alt}+=`],
    label: IS_MAC_OS ? '⌘ Opt +' : 'Ctrl Alt +',
    description: 'Zoom in',
  },
  zoom_out: {
    hotkey: `mod+${alt}+-`,
    label: IS_MAC_OS ? '⌘ Opt -' : 'Ctrl Alt -',
    description: 'Zoom out',
  },
  zoom_reset: {
    hotkey: `mod+${alt}+0`,
    label: IS_MAC_OS ? '⌘ Opt 0' : 'Ctrl Alt 0',
    description: 'Reset zoom',
  },
  zoom_fit: {
    hotkey: `mod+${alt}+[`,
    label: IS_MAC_OS ? '⌘ Opt [' : 'Ctrl Alt [',
    description: 'Fit to screen',
  },
  copy: {
    hotkey: 'mod+c',
    label: IS_MAC_OS ? '⌘ C' : 'Ctrl C',
    description: 'Copy recipe step',
  },
  paste_below: {
    hotkey: 'mod+v',
    label: IS_MAC_OS ? '⌘ V' : 'Ctrl V',
    description: 'Paste recipe step below',
  },
  paste_above: {
    hotkey: `mod+${alt}+v`,
    label: IS_MAC_OS ? '⌘ Opt V' : 'Ctrl Alt V',
    description: 'Paste recipe step above',
  },
  switch_team: {
    hotkey: `mod+${alt}+t`,
    label: IS_MAC_OS ? '⌘ Opt T' : 'Ctrl Alt T',
    description: 'Switch team',
  },
  fullscreen: {
    hotkey: 'mod+enter',
    label: IS_MAC_OS ? '⌘ Enter' : 'Ctrl Enter',
    description: 'Enter/exit fullscreen',
  },
  add_column: {
    hotkey: 'mod+i',
    label: IS_MAC_OS ? '⌘ i' : 'Ctrl i',
    description: 'Add column',
  },
  add_row: {
    hotkey: 'shift+enter',
    label: 'Shift Enter',
    description: 'Add record',
  },
  undo: {
    hotkey: 'mod+z',
    label: IS_MAC_OS ? '⌘ z' : 'Ctrl z',
    description: 'Undo last changes',
  },
  redo: {
    hotkey: 'mod+shift+z',
    label: IS_MAC_OS ? '⌘ shift z' : 'Ctrl shift z',
    description: 'Redo last reverted changes',
  },
};

class HotkeyWithPriority extends Hotkey {
  readonly priority: number;

  constructor(combo: string | string[], callback: (event: KeyboardEvent) => boolean, opts: HotkeyOptions) {
    super(combo, callback, opts.allowIn, opts.description);
    this.priority = opts.priority || 0;
  }
}

@Injectable()
export class Hotkeys implements OnDestroy {
  protected hotkeys = inject(NgHotkeysService);
  protected mixpanelService = inject(MixpanelService);

  private callbackToHotkeysMap = new Map<HotkeyCallback, Hotkey | Hotkey[]>();

  private subscription?: Subscription;

  ngOnDestroy() {
    this.removeAll();
    this.subscription?.unsubscribe();
  }

  add(hotkeys: string | string[], callback: HotkeyCallback, opts: HotkeyOptions = {}): HotkeyManager {
    const toggle = (flag: boolean) => {
      this.remove(callback);

      if (flag) {
        const hotkey = this.hotkeys.add(new HotkeyWithPriority(hotkeys, this.wrapCallback(callback, opts), opts));

        if (opts.removable !== false) {
          this.callbackToHotkeysMap.set(callback, hotkey);
        }

        this.rearrangeHotkeys();
      }
    };

    toggle(true);

    return {
      toggle,
    };
  }

  remove(callback: HotkeyCallback) {
    const hotkeys = this.callbackToHotkeysMap.get(callback);

    if (hotkeys) {
      this.hotkeys.remove(hotkeys);
      this.callbackToHotkeysMap.delete(callback);
    }
  }

  removeAll() {
    this.callbackToHotkeysMap.forEach(hotkeys => this.hotkeys.remove(hotkeys));
    this.callbackToHotkeysMap.clear();
  }

  toggleAll(flag: boolean) {
    this.callbackToHotkeysMap.forEach(hotkeys => (flag ? this.hotkeys.unpause(hotkeys) : this.hotkeys.pause(hotkeys)));
    this.rearrangeHotkeys();
  }

  openCheatsheet() {
    this.hotkeys.cheatSheetToggle.next(true);
  }

  closeCheatsheet() {
    this.hotkeys.cheatSheetToggle.next(false);
  }

  showOwnHotkeys() {
    const ownHotkeys = [...this.callbackToHotkeysMap.values()].flat();
    const otherVisibleHotkeys = difference(this.hotkeys.hotkeys, ownHotkeys).filter(hotkey => hotkey.description);

    otherVisibleHotkeys.forEach(hotkey => this.hotkeys.pause(hotkey));
    this.openCheatsheet();

    this.subscription?.unsubscribe();
    this.subscription = this.hotkeys.cheatSheetToggle
      .pipe(
        filter(isOpened => !isOpened),
        take(1),
      )
      .subscribe(() => {
        otherVisibleHotkeys.forEach(hotkey => this.hotkeys.unpause(hotkey));
        this.rearrangeHotkeys();
      });
  }

  private rearrangeHotkeys() {
    const {hotkeys} = this.hotkeys;

    hotkeys.sort((first, second) => (this.getPriority(first) <= this.getPriority(second) ? 1 : -1));
  }

  private getPriority(hotkey: Hotkey): number {
    const priority = (hotkey as HotkeyWithPriority).priority;

    // Built-in hotkey for `?` must have top priority
    return isNumber(priority) ? priority : Infinity;
  }

  private wrapCallback(callback: HotkeyCallback, options: HotkeyOptions): (event: KeyboardEvent) => boolean {
    return (event: KeyboardEvent) => {
      if (options.description) {
        this.mixpanelService.track('Hotkeys: was caused', {description: options.description});
      }

      return callback(event) === true;
    };
  }
}
