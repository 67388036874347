import _ from 'lodash';
import {Injectable} from '@angular/core';

import {nonNullable} from '@shared/utils/non-nullable';

import {
  ExpressionContext,
  FieldHelper,
  FieldQualifiedPath,
  FieldsInputWithContext,
} from '../modules/form-fields/field-helper.service';
import {
  AbstractArrayField,
  AbstractStaticListField,
  CustomTemplateField,
  DynamicListField,
  FieldsInput,
  RecipeStep,
  SchemaField,
  StepKeyword,
  ToggleField,
} from '../types';

import {CONDITION_SCHEMA, KEYWORDS_CONFIG} from './app-dictionary/data';

interface ForEachFieldContext {
  parents: SchemaField[];
  // Calling it within iteration callback will inform method to skip iteration through children of current field
  skipChildren: () => void;
}

interface ForEachFieldPrivateContext {
  shouldSkipChildren: boolean;
}

interface ForEachFieldOptions {
  requiredOnly?: boolean;
  includeKeyValue?: boolean;
  includeToggleField?: boolean;
}

type ForEachFieldCallback = (field: SchemaField, context: ForEachFieldContext) => void | false;

interface MappingCheckOptions {
  requiredOnly?: boolean;
  ignoreParent?: boolean;
  ignoreDefaults?: boolean;
  mode?: 'every' | 'some';
}

@Injectable({
  providedIn: 'root',
})
export class SchemaHelper {
  constructor(private fieldHelper: FieldHelper) {}

  getDefaults(schema: SchemaField[], input?: FieldsInput): FieldsInput {
    const defaults: any = {};

    this.forEachField(schema, (field, {skipChildren}) => {
      if (
        this.fieldHelper.isKeyValueField(field) ||
        this.fieldHelper.isStaticListField(field) ||
        (input && this.fieldHelper.isListFieldInStaticMode(field, input))
      ) {
        // Defaults for items in static list fields and key-value fields are set in the corresponding components
        skipChildren();

        return;
      }

      if (
        this.fieldHelper.isToggleField(field) &&
        field.name !== field.toggle_field.name &&
        input &&
        this.fieldHelper.isMapped(field.toggle_field, input)
      ) {
        /*
         * We need this so that we don't end up with two values for the toggle field:
         * default value for primary field and actual mapped value for the secondary field.
         * This situation makes backend think that primary field is selected instead of secondary.
         * Basically, backend expects only one value to be set for the toggle-field at all times.
         */
        return;
      }

      if (_.toString(field.default) !== '') {
        _.set(defaults, this.fieldHelper.getQualifiedPath(field), _.toString(field.default));
      }
    });

    return defaults;
  }

  fillDefaults(schema: SchemaField[], input: FieldsInput): any {
    return _.defaultsDeep(input, this.getDefaults(schema, input));
  }

  forEachField(
    fields: SchemaField[] | undefined,
    callback: ForEachFieldCallback,
    opts: ForEachFieldOptions = {},
  ): void {
    if (!fields) {
      return;
    }

    const context: ForEachFieldContext & ForEachFieldPrivateContext = {
      parents: [],
      shouldSkipChildren: false,
      skipChildren: () => (context.shouldSkipChildren = true),
    };

    this.iterateThroughFields(fields, callback, opts, context);
  }

  forEachRenderedField(
    fields: SchemaField[],
    input: FieldsInput,
    callback: (field: SchemaField, context: ForEachFieldContext) => void | false,
    opts?: Omit<ForEachFieldOptions, 'includeToggleField'>,
  ): void {
    const fieldsForEachFieldPath = this.getFieldsForEachRenderedField(fields, input);

    this.forEachField(fieldsForEachFieldPath, callback, {...opts, includeToggleField: true});
  }

  findField(
    schema: SchemaField[],
    fieldPath: FieldQualifiedPath,
    matcher?: (field: SchemaField) => boolean,
  ): SchemaField | undefined {
    const fieldPathWithoutIndexes = this.fieldHelper.removeIndexesFromQualifiedPath(fieldPath);

    const parts: FieldQualifiedPath = _.without(fieldPathWithoutIndexes, 'first');
    let field: SchemaField | undefined;
    let isToggleField = false;

    while (parts.length) {
      const name = parts.shift();
      let index = -1;

      const callback = (schemaField: SchemaField) => {
        if (schemaField && this.fieldHelper.isToggleField(schemaField) && schemaField.toggle_field.name === name) {
          isToggleField = true;

          return true;
        }

        return schemaField.name === name;
      };

      if (matcher && !parts.length) {
        do {
          index = _.findIndex(schema, callback, index + 1);
        } while (index >= 0 && !matcher(schema[index]));
      } else {
        index = _.findIndex(schema, callback);
      }

      if (index === -1) {
        return;
      }

      if (isToggleField) {
        field = (schema[index] as ToggleField).toggle_field;
      } else {
        field = schema[index];
      }

      schema = field && this.fieldHelper.hasChildren(field) ? field.properties : [];
    }

    return field;
  }

  unsetFieldValueByPath(path: FieldQualifiedPath, input: FieldsInput, schema: SchemaField[]) {
    const fieldPath = [...path];
    const rootFieldName = fieldPath.shift()!;
    const field = schema.find(schemaField => schemaField.name === rootFieldName);

    if (!field) {
      return;
    }

    const fieldValue = this.fieldHelper.getRawValue(field, input);

    if (!fieldValue) {
      return;
    }

    if (!fieldPath.length) {
      this.fieldHelper.unsetValue(field, input);

      return;
    }

    if (this.fieldHelper.isListFieldInStaticMode(field, fieldValue) || this.fieldHelper.isKeyValueField(field)) {
      this.fieldHelper.unsetListItemField(field, fieldPath);
    } else if (this.fieldHelper.hasChildren(field)) {
      this.unsetFieldValueByPath(fieldPath, input, field.properties);
    }
  }

  hasMapping(
    fields: SchemaField[],
    inputWithContext: FieldsInputWithContext,
    options: MappingCheckOptions = {},
  ): boolean {
    options.mode = options.mode || 'every';

    const visibleFields = fields.filter(field => this.fieldHelper.isVisible(field, inputWithContext));
    const checkFieldValue = (field: SchemaField) => this.checkMapping(field, inputWithContext, options);

    return options.mode === 'some' ? visibleFields.some(checkFieldValue) : visibleFields.every(checkFieldValue);
  }

  sanitizeInput(fields: SchemaField[], input: FieldsInput): FieldsInput {
    if (_.isEmpty(input)) {
      return input;
    }

    _.forEach(fields, field => {
      const value = input[field.name];

      if (this.fieldHelper.isValidValue(field, value)) {
        if (this.fieldHelper.hasChildren(field) && !_.isEmpty(value)) {
          if (_.isArray(value)) {
            _.forEach(value, item => this.sanitizeInput(field.properties, item));
          } else {
            this.sanitizeInput(field.properties, value);
          }
        }
      } else {
        this.fieldHelper.unsetValue(field, input, {ignoreParent: true});
      }
    });

    return input;
  }

  forEachMatchedField(
    schema: SchemaField[],
    queryRegexp: RegExp,
    callback: (field: SchemaField, context: ForEachFieldContext) => void | false,
    opts?: ForEachFieldOptions,
  ) {
    this.forEachField(
      schema,
      (field, context) => {
        if (queryRegexp.test(field.label!)) {
          return callback(field, context);
        }
      },
      opts,
    );
  }

  hasMatchedVisibleFields(schema: SchemaField[], queryRegexp: RegExp, expressionContext: ExpressionContext): boolean {
    let hasMatches = false;

    this.forEachField(schema, (field, context) => {
      if (!this.fieldHelper.isVisible(field, expressionContext)) {
        context.skipChildren();

        return;
      }

      if (queryRegexp.test(field.label!)) {
        hasMatches = true;

        return false;
      }
    });

    return hasMatches;
  }

  defineParentForSchema(schema: SchemaField[], parent?: SchemaField, notify = true) {
    const fieldsWithChangedParent: SchemaField[] = [];

    this.forEachField(
      schema,
      (field, context) => {
        const parentField = _.last(context.parents) || parent;

        if (parentField && this.fieldHelper.defineParent(field, parentField, undefined, false).parentChanged) {
          fieldsWithChangedParent.push(field);
        }
      },
      {
        includeToggleField: true,
        includeKeyValue: true,
      },
    );

    if (notify) {
      this.fieldHelper.notifyBulkParentsChange(fieldsWithChangedParent);
    }
  }

  deleteParentsInSchema(schema: SchemaField[]) {
    this.forEachField(
      schema,
      field => {
        delete field.parent;
      },
      {includeKeyValue: true, includeToggleField: true},
    );
  }

  defineParentForListItem(
    listItemSchema: SchemaField[],
    listField: AbstractStaticListField,
    index: number,
    notify = true,
  ): SchemaField[] {
    const directChildren = listItemSchema.concat(
      listItemSchema
        .map(field => (this.fieldHelper.isToggleField(field) ? field.toggle_field : null))
        .filter(nonNullable),
    );

    const directChildrenWithChangedParent = directChildren
      .map(child => {
        const defineResult = this.fieldHelper.defineParent(child, listField, index, false);

        return defineResult?.parentChanged ? defineResult.field : null;
      })
      .filter(nonNullable);

    if (notify) {
      this.fieldHelper.notifyBulkParentsChange(directChildrenWithChangedParent);
    }

    this.defineParentForSchema(directChildren, undefined, notify);

    return listItemSchema;
  }

  getFilterField(keyword: StepKeyword): DynamicListField {
    const properties = this.getConditionSchema();

    if (keyword === 'trigger') {
      properties[0].label = 'Trigger data';
    } else if (keyword === 'catch') {
      properties[0].label = 'Data';
    }

    return {
      type: 'array',
      of: 'object',
      name: 'conditions',
      properties,
    };
  }

  getDynamicMappingSourceField(): CustomTemplateField {
    return {
      name: 'sources' satisfies keyof NonNullable<RecipeStep['external_input_definition']>,
      parent: ['external_input_definition' satisfies keyof RecipeStep],
      control_type: 'custom',
      label: 'Source',
      hint: 'Specify the step to use as the source of data',
    };
  }

  getConditionSchema(): typeof CONDITION_SCHEMA {
    return _.cloneDeep(CONDITION_SCHEMA);
  }

  getSchemaByKeyword(keyword: StepKeyword): SchemaField[] {
    const schema = KEYWORDS_CONFIG[keyword]?.config;

    return Array.isArray(schema) ? _.cloneDeep(schema) : [];
  }

  private checkMapping(
    field: SchemaField,
    {input, ...context}: FieldsInputWithContext,
    options: MappingCheckOptions,
  ): boolean {
    if (options.requiredOnly && this.fieldHelper.isOptional(field, {input, ...context})) {
      return true;
    }

    if (!this.fieldHelper.hasChildren(field)) {
      return this.fieldHelper.isMapped(field, input, options);
    }

    const value = field.synthetic
      ? input
      : this.fieldHelper.getRawValue(field, input, {ignoreParent: options.ignoreParent});
    const checkChildMapping = (childInput: FieldsInput) =>
      this.hasMapping(field.properties, {...context, input: childInput}, {...options, ignoreParent: true});

    if (this.fieldHelper.isListFieldInStaticMode(field, value) || this.fieldHelper.isKeyValueField(field)) {
      return !_.isEmpty(value) && _.every(value, checkChildMapping);
    } else {
      return checkChildMapping(value);
    }
  }

  private getFieldsForEachRenderedField(fields: SchemaField[], input: FieldsInput): SchemaField[] {
    const clonedFields = _.cloneDeep(fields);

    return this.getFieldsForEachRenderedFieldInner(clonedFields, input);
  }

  private getFieldsForEachRenderedFieldInner(fields: SchemaField[], input: FieldsInput): SchemaField[] {
    fields.forEach(field => {
      if (this.fieldHelper.isListField(field) || this.fieldHelper.isKeyValueField(field)) {
        const fieldInput = _.get(input, this.fieldHelper.getQualifiedPath(field));

        if (Array.isArray(fieldInput)) {
          field.properties = fieldInput.flatMap((_value, index) =>
            this.defineParentForListItem(_.cloneDeep(field.properties), field, index, false),
          );
        } else {
          delete (field as AbstractArrayField).properties;
        }
      }

      if (this.fieldHelper.isComplexField(field) || this.fieldHelper.isSchemaBuilderField(field)) {
        this.defineParentForSchema([field], undefined, false);
      }

      if (this.fieldHelper.hasChildren(field)) {
        field.properties = this.getFieldsForEachRenderedFieldInner(field.properties, input);
      }
    });

    return fields;
  }

  private iterateThroughFields(
    fields: SchemaField[],
    callback: ForEachFieldCallback,
    opts: ForEachFieldOptions,
    context: ForEachFieldContext & ForEachFieldPrivateContext,
  ): boolean {
    let cancelled = false;

    _.forEach(fields, (field: SchemaField) => {
      if (opts.requiredOnly && field.optional) {
        return;
      }

      if (callback(field, context) === false) {
        cancelled = true;

        return false;
      }

      if (
        opts.includeToggleField &&
        this.fieldHelper.isToggleField(field) &&
        !this.iterateThroughFields([field.toggle_field], callback, opts, context)
      ) {
        cancelled = true;

        return false;
      }

      if (context.shouldSkipChildren) {
        context.shouldSkipChildren = false;

        return;
      }

      if (this.fieldHelper.hasChildren(field) && (opts.includeKeyValue || !this.fieldHelper.isKeyValueField(field))) {
        context.parents.push(field);

        if (!this.iterateThroughFields(field.properties, callback, opts, context)) {
          cancelled = true;

          return false;
        }

        context.parents.pop();
      }
    });

    return !cancelled;
  }
}
