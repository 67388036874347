import {CssDerivedColor, CssVariable} from './theme-types';

export const FONT_VARIABLES = {
  'h1-font-size': new CssVariable('font-size', 'Heading 1', undefined, '28px'),
  'h2-font-size': new CssVariable('font-size', 'Heading 2', undefined, '24px'),
  'h3-font-size': new CssVariable('font-size', 'Heading 3', undefined, '20px'),
  'h4-font-size': new CssVariable('font-size', 'Heading 4', undefined, '16px'),
  'h5-font-size': new CssVariable('font-size', 'Heading 5', undefined, '14px'),
  'h6-font-size': new CssVariable('font-size', 'Heading 6', undefined, '14px'),
  'default-font-size': new CssVariable('font-size', 'Default', undefined, '14px'),
  'primary-font-family': new CssVariable(
    'font-family',
    'Primary font',
    'Use a custom font in your theme.',
    '"Source Sans Pro", Helvetica, Arial, sans-serif',
  ),
};

export const EMBEDDED_VARIABLES = {
  'container-margin-left': new CssVariable('margin', undefined, undefined, 'auto'),
  'container-margin-right': new CssVariable('margin', undefined, undefined, 'auto'),
  'container-padding-left': new CssVariable('margin', undefined, undefined, '32px'),
  'container-padding-right': new CssVariable('margin', undefined, undefined, '32px'),
};

export const SURFACE_VARIABLES = {
  'backdrop-default': new CssVariable('background-color', 'Default background', 'For pages, modals and side panels.'),
  'surface-subtle': new CssVariable(
    'background-color',
    'Secondary background',
    'For secondary content or disabled state.',
  ),
  'surface-alt': new CssVariable('background-color', 'Alternate background', 'For table headers and tags.'),
  'surface-field': new CssVariable('background-color', 'Field background', 'For input fields and canvas.'),
  'surface-default': new CssVariable('background-color', 'Card background'),
  'surface-elevated': new CssVariable('background-color', 'Card hover background'),
};

export const TEXT_VARIABLES = {
  'text-primary': new CssVariable('foreground-color', 'Primary text'),
  'text-secondary': new CssVariable('foreground-color', 'Secondary text'),
  'text-emphasis': new CssVariable(
    'foreground-color',
    'Emphasis text',
    'To create contrast against default background.',
  ),
};

export const BRAND_VARIABLES = {
  'backdrop-landing': new CssVariable('background-color', 'Login page background'),
  'brand-default': new CssVariable('background-color', 'Login button'),
  'brand-emphasis': new CssVariable('background-color', 'Login button hover'),
  'fg-onBrand': new CssVariable('foreground-color', 'Login button text'),
};

export const SIDEBAR_VARIABLES = {
  'sidebar-main': new CssVariable('background-color', 'Sidebar main menu'),
  'sidebar-submenu': new CssVariable('background-color'),
  'sidebar-fg-primary': new CssVariable('foreground-color', 'Sidebar primary text'),
  'sidebar-fg-secondary': new CssVariable('foreground-color', 'Sidebar secondary text'),
  'sidebar-active': new CssVariable('background-color', 'Sidebar active background'),
  'sidebar-fg-active': new CssVariable('foreground-color', 'Sidebar active text'),
};

export const ACCENT_MAIN_VARIABLES = {
  'accent-default': new CssVariable('background-color'),
  'fg-onAccent': new CssVariable('foreground-color', 'Text on accent background'),
  'accent-emphasis': new CssVariable('background-color'),
  'accent-muted': new CssVariable('background-color'),
  'accent-subtle': new CssVariable('background-color'),
  'accent-tint': new CssVariable('background-color'),
};

export const RECIPE_EDITOR_MAIN_VARIABLES = {
  'edit-dark': new CssVariable('background-color', 'Header background'),
  'fg-onEdit-dark': new CssVariable('foreground-color', 'Header text'),
  'edit-toggle-bg': new CssVariable('background-color', 'Toggle background'),
  'fg-onEdit-toggle': new CssVariable('foreground-color', 'Toggle text'),
  'edit-default': new CssVariable('background-color', 'Button background'),
  'edit-emphasis': new CssVariable('background-color', 'Button hover background'),
  'fg-onEdit': new CssVariable('foreground-color', 'Button text'),
};

export const TEST_JOB_MAIN_VARIABLES = {
  'test-dark': new CssVariable('background-color', 'Header background'),
  'fg-onTest-dark': new CssVariable('foreground-color', 'Header text'),
  'test-toggle-bg': new CssVariable('background-color', 'Toggle background'),
  'fg-onTest-toggle': new CssVariable('foreground-color', 'Toggle text'),
  'test-default': new CssVariable('background-color', 'Button background'),
  'test-emphasis': new CssVariable('background-color', 'Button hover background'),
  'fg-onTest': new CssVariable('foreground-color', 'Button text'),
};

export const TEST_CASE_MAIN_VARIABLES = {
  'test-alt-dark': new CssVariable('background-color', 'Header background'),
  'fg-onTest-alt-dark': new CssVariable('foreground-color', 'Header text'),
  'test-alt-toggle-bg': new CssVariable('background-color', 'Toggle background'),
  'fg-onTest-alt-toggle': new CssVariable('foreground-color', 'Toggle text'),
  'test-alt-default': new CssVariable('background-color', 'Button background'),
  'test-alt-emphasis': new CssVariable('background-color', 'Button hover background'),
  'fg-onTest-alt': new CssVariable('foreground-color', 'Button text'),
};

export const TEST_CASE_EXTENDED_VARIABLES = {
  'test-alt-muted': new CssVariable('background-color', 'Muted variant'),
  'test-alt-subtle': new CssVariable('background-color', 'Subtle variant'),
  'test-alt-tint': new CssVariable('background-color', 'Tint variant'),
};

export const AI_VARIABLES = {
  'ai-default': new CssVariable('background-color'),
  'ai-emphasis': new CssVariable('background-color'),
  'ai-tint': new CssVariable('background-color'),
  'ai-subtle': new CssVariable('background-color'),
  'ai-muted': new CssVariable('background-color'),
  'ai-fg': new CssVariable('foreground-color'),
  'ai-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onAi': new CssVariable('foreground-color'),
  'ai-border': new CssVariable('border-color'),
  'ai-border-emphasis': new CssVariable('border-color'),

  'ai-alt-default': new CssVariable('background-color'),
  'ai-alt-emphasis': new CssVariable('background-color'),
  'ai-alt-tint': new CssVariable('background-color'),
  'ai-alt-subtle': new CssVariable('background-color'),
  'ai-alt-muted': new CssVariable('background-color'),
  'ai-alt-fg': new CssVariable('foreground-color'),
  'ai-alt-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onAi-alt': new CssVariable('foreground-color'),
  'ai-alt-border': new CssVariable('border-color'),
  'ai-alt-border-emphasis': new CssVariable('border-color'),

  'copilot-primary': new CssVariable('background-color'),
  'copilot-secondary': new CssVariable('background-color'),
  'copilot-alt': new CssVariable('background-color'),
};

const CHART_VARIABLES = {
  'chart-teal-muted': new CssVariable('background-color'),
  'chart-teal-subtle': new CssVariable('background-color'),
  'chart-teal-tint': new CssVariable('background-color'),
  'chart-teal-fg': new CssVariable('foreground-color'),
  'chart-teal-border': new CssVariable('border-color'),
  'chart-blue-muted': new CssVariable('background-color'),
  'chart-blue-subtle': new CssVariable('background-color'),
  'chart-blue-tint': new CssVariable('background-color'),
  'chart-blue-fg': new CssVariable('foreground-color'),
  'chart-blue-border': new CssVariable('border-color'),
  'chart-violet-muted': new CssVariable('background-color'),
  'chart-violet-subtle': new CssVariable('background-color'),
  'chart-violet-tint': new CssVariable('background-color'),
  'chart-violet-fg': new CssVariable('foreground-color'),
  'chart-violet-border': new CssVariable('border-color'),
  'chart-red-muted': new CssVariable('background-color'),
  'chart-red-subtle': new CssVariable('background-color'),
  'chart-red-tint': new CssVariable('background-color'),
  'chart-red-fg': new CssVariable('foreground-color'),
  'chart-red-border': new CssVariable('border-color'),
  'chart-green-muted': new CssVariable('background-color'),
  'chart-green-subtle': new CssVariable('background-color'),
  'chart-green-tint': new CssVariable('background-color'),
  'chart-green-fg': new CssVariable('foreground-color'),
  'chart-green-border': new CssVariable('border-color'),
  'chart-brown-muted': new CssVariable('background-color'),
  'chart-brown-subtle': new CssVariable('background-color'),
  'chart-brown-tint': new CssVariable('background-color'),
  'chart-brown-fg': new CssVariable('foreground-color'),
  'chart-brown-border': new CssVariable('border-color'),
  'chart-orange-muted': new CssVariable('background-color'),
  'chart-orange-subtle': new CssVariable('background-color'),
  'chart-orange-tint': new CssVariable('background-color'),
  'chart-orange-fg': new CssVariable('foreground-color'),
  'chart-orange-border': new CssVariable('border-color'),
  'chart-gold-muted': new CssVariable('background-color'),
  'chart-gold-subtle': new CssVariable('background-color'),
  'chart-gold-tint': new CssVariable('background-color'),
  'chart-gold-fg': new CssVariable('foreground-color'),
  'chart-gold-border': new CssVariable('border-color'),
  'chart-indigo-muted': new CssVariable('background-color'),
  'chart-indigo-subtle': new CssVariable('background-color'),
  'chart-indigo-tint': new CssVariable('background-color'),
  'chart-indigo-fg': new CssVariable('foreground-color'),
  'chart-indigo-border': new CssVariable('border-color'),
  'chart-plum-muted': new CssVariable('background-color'),
  'chart-plum-subtle': new CssVariable('background-color'),
  'chart-plum-tint': new CssVariable('background-color'),
  'chart-plum-fg': new CssVariable('foreground-color'),
  'chart-plum-border': new CssVariable('border-color'),
  'chart-slate-muted': new CssVariable('background-color'),
  'chart-slate-subtle': new CssVariable('background-color'),
  'chart-slate-tint': new CssVariable('background-color'),
  'chart-slate-fg': new CssVariable('foreground-color'),
  'chart-slate-border': new CssVariable('border-color'),
  'chart-rose-muted': new CssVariable('background-color'),
  'chart-rose-subtle': new CssVariable('background-color'),
  'chart-rose-tint': new CssVariable('background-color'),
  'chart-rose-fg': new CssVariable('foreground-color'),
  'chart-rose-border': new CssVariable('border-color'),
  'chart-yellow-muted': new CssVariable('background-color'),
  'chart-yellow-subtle': new CssVariable('background-color'),
  'chart-yellow-tint': new CssVariable('background-color'),
  'chart-yellow-fg': new CssVariable('foreground-color'),
  'chart-yellow-border': new CssVariable('border-color'),
  'chart-cobalt-muted': new CssVariable('background-color'),
  'chart-cobalt-subtle': new CssVariable('background-color'),
  'chart-cobalt-tint': new CssVariable('background-color'),
  'chart-cobalt-fg': new CssVariable('foreground-color'),
  'chart-cobalt-border': new CssVariable('border-color'),
};

export const THEME_VARIABLES = {
  /* Customisable(general) variables */
  ...SURFACE_VARIABLES,
  'surface-inverse': new CssVariable('background-color'),

  ...TEXT_VARIABLES,
  'text-inverse': new CssVariable('foreground-color'),

  'link-default': new CssVariable('foreground-color'),
  'link-emphasis': new CssVariable('foreground-color'),

  'icon-default': new CssVariable('background-color'),
  'icon-emphasis': new CssVariable('background-color'),
  'icon-inverse': new CssVariable('background-color'),

  'border-default': new CssVariable('border-color'),
  'border-subtle': new CssVariable('border-color'),
  'border-inverse': new CssVariable('border-color'),

  ...BRAND_VARIABLES,
  ...SIDEBAR_VARIABLES,

  /* Customisable(functional) variables */

  'neutral-tint': new CssVariable('background-color'),
  'neutral-subtle': new CssVariable('background-color'),
  'neutral-muted': new CssVariable('background-color'),
  'neutral-bold': new CssVariable('background-color'),
  'neutral-strong': new CssVariable('background-color'),
  'neutral-light': new CssVariable('background-color'),
  'neutral-default': new CssVariable('background-color'),
  'neutral-emphasis': new CssVariable('background-color'),
  'neutral-dark': new CssVariable('background-color'),
  'neutral-fg': new CssVariable('foreground-color'),
  'neutral-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onNeutral': new CssVariable('foreground-color'),
  'fg-onNeutral-light': new CssVariable('foreground-color'),
  'fg-onNeutral-dark': new CssVariable('foreground-color'),
  'neutral-border': new CssVariable('border-color'),
  'neutral-border-emphasis': new CssVariable('border-color'),

  ...ACCENT_MAIN_VARIABLES,
  'accent-fg': new CssVariable('foreground-color'),
  'accent-fg-emphasis': new CssVariable('foreground-color'),
  'accent-border': new CssVariable('border-color'),
  'accent-border-emphasis': new CssVariable('border-color'),

  ...RECIPE_EDITOR_MAIN_VARIABLES,
  'edit-tint': new CssVariable('background-color'),
  'edit-subtle': new CssVariable('background-color'),
  'edit-bold': new CssVariable('background-color'),
  'edit-fg': new CssVariable('foreground-color'),
  'edit-fg-emphasis': new CssVariable('foreground-color'),
  'edit-border': new CssVariable('border-color'),
  'edit-border-emphasis': new CssVariable('border-color'),
  'edit-muted': new CssVariable('background-color'),

  ...TEST_JOB_MAIN_VARIABLES,
  'test-tint': new CssVariable('background-color'),
  'test-bold': new CssVariable('background-color'),
  'test-subtle': new CssVariable('background-color'),
  'test-fg': new CssVariable('foreground-color'),
  'test-fg-emphasis': new CssVariable('foreground-color'),
  'test-border': new CssVariable('border-color'),
  'test-border-emphasis': new CssVariable('border-color'),
  'test-muted': new CssVariable('background-color'),

  ...TEST_CASE_MAIN_VARIABLES,
  ...TEST_CASE_EXTENDED_VARIABLES,
  'test-alt-bold': new CssVariable('background-color'),
  'test-alt-fg': new CssVariable('foreground-color'),
  'test-alt-fg-emphasis': new CssVariable('foreground-color'),
  'test-alt-border': new CssVariable('border-color'),
  'test-alt-border-emphasis': new CssVariable('border-color'),

  'info-tint': new CssVariable('background-color'),
  'info-subtle': new CssVariable('background-color'),
  'info-muted': new CssVariable('background-color'),
  'info-default': new CssVariable('background-color'),
  'info-emphasis': new CssVariable('background-color'),
  'info-fg': new CssVariable('foreground-color'),
  'info-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onInfo': new CssVariable('foreground-color'),
  'info-border': new CssVariable('border-color'),
  'info-border-emphasis': new CssVariable('border-color'),

  'success-tint': new CssVariable('background-color'),
  'success-subtle': new CssVariable('background-color'),
  'success-muted': new CssVariable('background-color'),
  'success-default': new CssVariable('background-color'),
  'success-emphasis': new CssVariable('background-color'),
  'success-fg': new CssVariable('foreground-color'),
  'success-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onSuccess': new CssVariable('foreground-color'),
  'success-border': new CssVariable('border-color'),
  'success-border-emphasis': new CssVariable('border-color'),

  'warning-tint': new CssVariable('background-color'),
  'warning-subtle': new CssVariable('background-color'),
  'warning-muted': new CssVariable('background-color'),
  'warning-default': new CssVariable('background-color'),
  'warning-emphasis': new CssVariable('background-color'),
  'warning-fg': new CssVariable('foreground-color'),
  'warning-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onWarning': new CssVariable('foreground-color'),
  'warning-border': new CssVariable('border-color'),
  'warning-border-emphasis': new CssVariable('border-color'),

  'error-tint': new CssVariable('background-color'),
  'error-subtle': new CssVariable('background-color'),
  'error-muted': new CssVariable('background-color'),
  'error-default': new CssVariable('background-color'),
  'error-emphasis': new CssVariable('background-color'),
  'error-fg': new CssVariable('foreground-color'),
  'error-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onError': new CssVariable('foreground-color'),
  'error-border': new CssVariable('border-color'),
  'error-border-emphasis': new CssVariable('border-color'),

  'notice-tint': new CssVariable('background-color'),
  'notice-subtle': new CssVariable('background-color'),
  'notice-muted': new CssVariable('background-color'),
  'notice-default': new CssVariable('background-color'),
  'notice-emphasis': new CssVariable('background-color'),
  'notice-fg': new CssVariable('foreground-color'),
  'notice-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onNotice': new CssVariable('foreground-color'),
  'notice-border': new CssVariable('border-color'),
  'notice-border-emphasis': new CssVariable('border-color'),

  /* Non-customisable theme-dependant (decorative) variables */

  'env-dev-tint': new CssVariable('background-color'),
  'env-dev-subtle': new CssVariable('background-color'),
  'env-dev-muted': new CssVariable('background-color'),
  'env-dev-default': new CssVariable('background-color'),
  'env-dev-emphasis': new CssVariable('background-color'),
  'env-dev-fg': new CssVariable('foreground-color'),
  'env-dev-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onEnv-dev': new CssVariable('foreground-color'),
  'env-dev-border': new CssVariable('border-color'),
  'env-dev-border-emphasis': new CssVariable('border-color'),

  'env-test-tint': new CssVariable('background-color'),
  'env-test-subtle': new CssVariable('background-color'),
  'env-test-muted': new CssVariable('background-color'),
  'env-test-default': new CssVariable('background-color'),
  'env-test-emphasis': new CssVariable('background-color'),
  'env-test-fg': new CssVariable('foreground-color'),
  'env-test-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onEnv-test': new CssVariable('foreground-color'),
  'env-test-border': new CssVariable('border-color'),
  'env-test-border-emphasis': new CssVariable('border-color'),

  'env-prod-tint': new CssVariable('background-color'),
  'env-prod-subtle': new CssVariable('background-color'),
  'env-prod-muted': new CssVariable('background-color'),
  'env-prod-default': new CssVariable('background-color'),
  'env-prod-emphasis': new CssVariable('background-color'),
  'env-prod-fg': new CssVariable('foreground-color'),
  'env-prod-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onEnv-prod': new CssVariable('foreground-color'),
  'env-prod-border': new CssVariable('border-color'),
  'env-prod-border-emphasis': new CssVariable('border-color'),

  'rust-tint': new CssVariable('background-color'),
  'rust-subtle': new CssVariable('background-color'),
  'rust-muted': new CssVariable('background-color'),
  'rust-default': new CssVariable('background-color'),
  'rust-emphasis': new CssVariable('background-color'),
  'rust-fg': new CssVariable('foreground-color'),
  'rust-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onRust': new CssVariable('foreground-color'),
  'rust-border': new CssVariable('border-color'),
  'rust-border-emphasis': new CssVariable('border-color'),

  'olive-tint': new CssVariable('background-color'),
  'olive-subtle': new CssVariable('background-color'),
  'olive-muted': new CssVariable('background-color'),
  'olive-default': new CssVariable('background-color'),
  'olive-emphasis': new CssVariable('background-color'),
  'olive-fg': new CssVariable('foreground-color'),
  'olive-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onOlive': new CssVariable('foreground-color'),
  'olive-border': new CssVariable('border-color'),
  'olive-border-emphasis': new CssVariable('border-color'),

  'plum-tint': new CssVariable('background-color'),
  'plum-subtle': new CssVariable('background-color'),
  'plum-muted': new CssVariable('background-color'),
  'plum-default': new CssVariable('background-color'),
  'plum-emphasis': new CssVariable('background-color'),
  'plum-fg': new CssVariable('foreground-color'),
  'plum-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onPlum': new CssVariable('foreground-color'),
  'plum-border': new CssVariable('border-color'),
  'plum-border-emphasis': new CssVariable('border-color'),

  'violet-tint': new CssVariable('background-color'),
  'violet-subtle': new CssVariable('background-color'),
  'violet-muted': new CssVariable('background-color'),
  'violet-default': new CssVariable('background-color'),
  'violet-emphasis': new CssVariable('background-color'),
  'violet-fg': new CssVariable('foreground-color'),
  'violet-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onViolet': new CssVariable('foreground-color'),
  'violet-border': new CssVariable('border-color'),
  'violet-border-emphasis': new CssVariable('border-color'),

  'purple-tint': new CssVariable('background-color'),
  'purple-subtle': new CssVariable('background-color'),
  'purple-muted': new CssVariable('background-color'),
  'purple-default': new CssVariable('background-color'),
  'purple-emphasis': new CssVariable('background-color'),
  'purple-fg': new CssVariable('foreground-color'),
  'purple-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onPurple': new CssVariable('foreground-color'),
  'purple-border': new CssVariable('border-color'),
  'purple-border-emphasis': new CssVariable('border-color'),

  'gold-tint': new CssVariable('background-color'),
  'gold-subtle': new CssVariable('background-color'),
  'gold-muted': new CssVariable('background-color'),
  'gold-default': new CssVariable('background-color'),
  'gold-emphasis': new CssVariable('background-color'),
  'gold-fg': new CssVariable('foreground-color'),
  'gold-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onGold': new CssVariable('foreground-color'),
  'gold-border': new CssVariable('border-color'),
  'gold-border-emphasis': new CssVariable('border-color'),

  'brown-tint': new CssVariable('background-color'),
  'brown-subtle': new CssVariable('background-color'),
  'brown-muted': new CssVariable('background-color'),
  'brown-default': new CssVariable('background-color'),
  'brown-emphasis': new CssVariable('background-color'),
  'brown-fg': new CssVariable('foreground-color'),
  'brown-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onBrown': new CssVariable('foreground-color'),
  'brown-border': new CssVariable('border-color'),
  'brown-border-emphasis': new CssVariable('border-color'),

  'forest-tint': new CssVariable('background-color'),
  'forest-subtle': new CssVariable('background-color'),
  'forest-muted': new CssVariable('background-color'),
  'forest-default': new CssVariable('background-color'),
  'forest-emphasis': new CssVariable('background-color'),
  'forest-fg': new CssVariable('foreground-color'),
  'forest-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onForest': new CssVariable('foreground-color'),
  'forest-border': new CssVariable('border-color'),
  'forest-border-emphasis': new CssVariable('border-color'),

  'teal-tint': new CssVariable('background-color'),
  'teal-subtle': new CssVariable('background-color'),
  'teal-muted': new CssVariable('background-color'),
  'teal-default': new CssVariable('background-color'),
  'teal-emphasis': new CssVariable('background-color'),
  'teal-fg': new CssVariable('foreground-color'),
  'teal-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onTeal': new CssVariable('foreground-color'),
  'teal-border': new CssVariable('border-color'),
  'teal-border-emphasis': new CssVariable('border-color'),

  'blue-tint': new CssVariable('background-color'),
  'blue-subtle': new CssVariable('background-color'),
  'blue-muted': new CssVariable('background-color'),
  'blue-default': new CssVariable('background-color'),
  'blue-emphasis': new CssVariable('background-color'),
  'blue-fg': new CssVariable('foreground-color'),
  'blue-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onBlue': new CssVariable('foreground-color'),
  'blue-border': new CssVariable('border-color'),
  'blue-border-emphasis': new CssVariable('border-color'),

  'indigo-tint': new CssVariable('background-color'),
  'indigo-subtle': new CssVariable('background-color'),
  'indigo-muted': new CssVariable('background-color'),
  'indigo-default': new CssVariable('background-color'),
  'indigo-emphasis': new CssVariable('background-color'),
  'indigo-fg': new CssVariable('foreground-color'),
  'indigo-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onIndigo': new CssVariable('foreground-color'),
  'indigo-border': new CssVariable('border-color'),
  'indigo-border-emphasis': new CssVariable('border-color'),

  'green-tint': new CssVariable('background-color'),
  'green-subtle': new CssVariable('background-color'),
  'green-muted': new CssVariable('background-color'),
  'green-default': new CssVariable('background-color'),
  'green-emphasis': new CssVariable('background-color'),
  'green-fg': new CssVariable('foreground-color'),
  'green-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onGreen': new CssVariable('foreground-color'),
  'green-border': new CssVariable('border-color'),
  'green-border-emphasis': new CssVariable('border-color'),

  'orange-tint': new CssVariable('background-color'),
  'orange-subtle': new CssVariable('background-color'),
  'orange-muted': new CssVariable('background-color'),
  'orange-default': new CssVariable('background-color'),
  'orange-emphasis': new CssVariable('background-color'),
  'orange-fg': new CssVariable('foreground-color'),
  'orange-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onOrange': new CssVariable('foreground-color'),
  'orange-border': new CssVariable('border-color'),
  'orange-border-emphasis': new CssVariable('border-color'),

  'red-tint': new CssVariable('background-color'),
  'red-subtle': new CssVariable('background-color'),
  'red-muted': new CssVariable('background-color'),
  'red-default': new CssVariable('background-color'),
  'red-emphasis': new CssVariable('background-color'),
  'red-fg': new CssVariable('foreground-color'),
  'red-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onRed': new CssVariable('foreground-color'),
  'red-border': new CssVariable('border-color'),
  'red-border-emphasis': new CssVariable('border-color'),

  'slate-tint': new CssVariable('background-color'),
  'slate-subtle': new CssVariable('background-color'),
  'slate-muted': new CssVariable('background-color'),
  'slate-default': new CssVariable('background-color'),
  'slate-emphasis': new CssVariable('background-color'),
  'slate-fg': new CssVariable('foreground-color'),
  'slate-fg-emphasis': new CssVariable('foreground-color'),
  'fg-onSlate': new CssVariable('foreground-color'),
  'slate-border': new CssVariable('border-color'),
  'slate-border-emphasis': new CssVariable('border-color'),

  'mktg-neutral-default': new CssVariable('background-color'),
  'mktg-neutral-emphasis': new CssVariable('background-color'),
  'fg-onMktg-neutral': new CssVariable('foreground-color'),

  'utility-bg-teal': new CssVariable('background-color'),
  'utility-bg-gold': new CssVariable('background-color'),
  'utility-bg-blue': new CssVariable('background-color'),
  'utility-bg-orange': new CssVariable('background-color'),
  'utility-bg-red': new CssVariable('background-color'),
  'utility-bg-slate': new CssVariable('background-color'),
  'utility-bg-neutral': new CssVariable('background-color'),
  'utility-fg-teal': new CssVariable('foreground-color'),
  'utility-fg-gold': new CssVariable('foreground-color'),
  'utility-fg-blue': new CssVariable('foreground-color'),
  'utility-fg-orange': new CssVariable('foreground-color'),
  'utility-fg-red': new CssVariable('foreground-color'),
  'utility-fg-slate': new CssVariable('foreground-color'),
  'utility-fg-neutral': new CssVariable('foreground-color'),
  'utility-border': new CssVariable('border-color'),

  ...AI_VARIABLES,
  ...CHART_VARIABLES,
};

/* Non-customisable variables, derived from another variable by applying opacity(in rgba format) */
export const DERIVED_VARIABLES = {
  'text-placeholder': new CssDerivedColor('text-secondary', 0.7),
  'icon-placeholder': new CssDerivedColor('icon-default', 0.7),
  'text-disabled': new CssDerivedColor('text-secondary', 0.5),
  'icon-disabled': new CssDerivedColor('icon-default', 0.5),

  'surface-opacity-15': new CssDerivedColor('surface-default', 0.15),
  'surface-opacity-50': new CssDerivedColor('surface-default', 0.5),
  'surface-opacity-70': new CssDerivedColor('surface-default', 0.7),
  'surface-opacity-80': new CssDerivedColor('surface-default', 0.8),
  'surface-opacity-95': new CssDerivedColor('surface-default', 0.95),
  'surface-subtle-opacity-70': new CssDerivedColor('surface-subtle', 0.7),
  'surface-inverse-opacity-3': new CssDerivedColor('surface-inverse', 0.03),
  'surface-inverse-opacity-5': new CssDerivedColor('surface-inverse', 0.05),
  'surface-inverse-opacity-8': new CssDerivedColor('surface-inverse', 0.08),
  'surface-inverse-opacity-15': new CssDerivedColor('surface-inverse', 0.15),
  'surface-inverse-opacity-70': new CssDerivedColor('surface-inverse', 0.7),
  'surface-alt-opacity-75': new CssDerivedColor('surface-alt', 0.75),
  'neutral-opacity-15': new CssDerivedColor('neutral-default', 0.15),
  'neutral-opacity-70': new CssDerivedColor('neutral-default', 0.7),
  'neutral-subtle-opacity-50': new CssDerivedColor('neutral-subtle', 0.5),
  'fg-onNeutral-dark-opacity-80': new CssDerivedColor('fg-onNeutral-dark', 0.8),
  'fg-onNeutral-dark-opacity-0': new CssDerivedColor('fg-onNeutral-dark', 0),
  'fg-onNeutral-dark-opacity-30': new CssDerivedColor('fg-onNeutral-dark', 0.3),
  'neutral-emphasis-opacity-15': new CssDerivedColor('neutral-emphasis', 0.15),
  'neutral-dark-opacity-80': new CssDerivedColor('neutral-dark', 0.8),
  'neutral-dark-opacity-75': new CssDerivedColor('neutral-dark', 0.75),
  'neutral-dark-opacity-60': new CssDerivedColor('neutral-dark', 0.6),
  'neutral-dark-opacity-20': new CssDerivedColor('neutral-dark', 0.2),
  'neutral-dark-opacity-15': new CssDerivedColor('neutral-dark', 0.15),
  'neutral-dark-opacity-8': new CssDerivedColor('neutral-dark', 0.08),
  'neutral-dark-opacity-3': new CssDerivedColor('neutral-dark', 0.03),
  'neutral-border-emphasis-opacity-8': new CssDerivedColor('neutral-border-emphasis', 0.08),
  'neutral-border-opacity-45': new CssDerivedColor('neutral-border', 0.45),
  'accent-subtle-opacity-50': new CssDerivedColor('accent-subtle', 0.5),
  'accent-border-opacity-70': new CssDerivedColor('accent-border', 0.7),
  'accent-border-opacity-50': new CssDerivedColor('accent-border', 0.5),
  'accent-border-opacity-45': new CssDerivedColor('accent-border', 0.45),
  'accent-border-opacity-30': new CssDerivedColor('accent-border', 0.3),
  'accent-border-emphasis-opacity-20': new CssDerivedColor('accent-border-emphasis', 0.2),
  'accent-muted-opacity-15': new CssDerivedColor('accent-muted', 0.15),
  'accent-muted-opacity-10': new CssDerivedColor('accent-muted', 0.1),
  'edit-border-opacity-50': new CssDerivedColor('edit-border', 0.5),
  'test-border-opacity-50': new CssDerivedColor('test-border', 0.5),
  'info-border-opacity-50': new CssDerivedColor('info-border', 0.5),
  'success-border-opacity-50': new CssDerivedColor('success-border', 0.5),
  'warning-border-opacity-50': new CssDerivedColor('warning-border', 0.5),
  'warning-muted-opacity-15': new CssDerivedColor('warning-muted', 0.15),
  'error-border-opacity-50': new CssDerivedColor('error-border', 0.5),
  'error-default-opacity-15': new CssDerivedColor('error-default', 0.15),
  'error-muted-opacity-15': new CssDerivedColor('error-muted', 0.15),
  'notice-border-opacity-50': new CssDerivedColor('notice-border', 0.5),
  'env-dev-opacity-80': new CssDerivedColor('env-dev-default', 0.8),
  'env-dev-opacity-30': new CssDerivedColor('env-dev-default', 0.3),
  'env-test-opacity-80': new CssDerivedColor('env-test-default', 0.8),
  'env-test-opacity-30': new CssDerivedColor('env-test-default', 0.3),
  'env-prod-opacity-80': new CssDerivedColor('env-prod-default', 0.8),
  'env-prod-opacity-30': new CssDerivedColor('env-prod-default', 0.3),
  'rust-opacity-80': new CssDerivedColor('rust-default', 0.8),
  'rust-opacity-30': new CssDerivedColor('rust-default', 0.3),
  'olive-opacity-80': new CssDerivedColor('olive-default', 0.8),
  'olive-opacity-30': new CssDerivedColor('olive-default', 0.3),
  'plum-opacity-80': new CssDerivedColor('plum-default', 0.8),
  'violet-opacity-80': new CssDerivedColor('violet-default', 0.8),
  'purple-opacity-80': new CssDerivedColor('purple-default', 0.8),
  'gold-opacity-80': new CssDerivedColor('gold-default', 0.8),
  'brown-opacity-80': new CssDerivedColor('brown-default', 0.8),
  'forest-opacity-80': new CssDerivedColor('forest-default', 0.8),
  'teal-opacity-80': new CssDerivedColor('teal-default', 0.8),
  'teal-opacity-40': new CssDerivedColor('teal-default', 0.4),
  'blue-opacity-80': new CssDerivedColor('blue-default', 0.8),
  'blue-subtle-opacity-50': new CssDerivedColor('blue-subtle', 0.5),
  'blue-subtle-opacity-20': new CssDerivedColor('blue-subtle', 0.2),
  'blue-muted-opacity-15': new CssDerivedColor('blue-muted', 0.15),
  'blue-border-emphasis-opacity-50': new CssDerivedColor('blue-border-emphasis', 0.5),
  'indigo-opacity-80': new CssDerivedColor('indigo-default', 0.8),
  'indigo-opacity-30': new CssDerivedColor('indigo-default', 0.3),
  'indigo-opacity-15': new CssDerivedColor('indigo-muted', 0.15),
  'green-opacity-80': new CssDerivedColor('green-default', 0.8),
  'green-muted-opacity-15': new CssDerivedColor('green-muted', 0.15),
  'green-subtle-opacity-50': new CssDerivedColor('green-subtle', 0.5),
  'green-border-emphasis-opacity-50': new CssDerivedColor('green-border-emphasis', 0.5),
  'orange-opacity-80': new CssDerivedColor('orange-default', 0.8),
  'orange-muted-opacity-15': new CssDerivedColor('orange-muted', 0.15),
  'orange-subtle-opacity-50': new CssDerivedColor('orange-subtle', 0.5),
  'red-opacity-80': new CssDerivedColor('red-default', 0.8),
  'red-subtle-opacity-50': new CssDerivedColor('red-subtle', 0.5),
  'red-subtle-opacity-20': new CssDerivedColor('red-subtle', 0.2),
  'red-muted-opacity-15': new CssDerivedColor('red-muted', 0.15),
  'red-border-emphasis-opacity-50': new CssDerivedColor('red-border-emphasis', 0.5),
  'slate-opacity-80': new CssDerivedColor('slate-default', 0.8),
  'fg-onEdit-opacity-80': new CssDerivedColor('fg-onEdit', 0.8),
  'fg-onEdit-opacity-15': new CssDerivedColor('fg-onEdit', 0.15),
  'fg-onEdit-dark-opacity-60': new CssDerivedColor('fg-onEdit-dark', 0.6),
  'fg-onEdit-dark-opacity-50': new CssDerivedColor('fg-onEdit-dark', 0.5),
  'fg-onEdit-dark-opacity-30': new CssDerivedColor('fg-onEdit-dark', 0.3),
  'fg-onEdit-dark-opacity-15': new CssDerivedColor('fg-onEdit-dark', 0.15),
  'fg-onEdit-toggle-opacity-60': new CssDerivedColor('fg-onEdit-toggle', 0.6),
  'fg-onTest-dark-opacity-60': new CssDerivedColor('fg-onTest-dark', 0.6),
  'fg-onTest-dark-opacity-50': new CssDerivedColor('fg-onTest-dark', 0.5),
  'fg-onTest-dark-opacity-30': new CssDerivedColor('fg-onTest-dark', 0.3),
  'fg-onTest-dark-opacity-15': new CssDerivedColor('fg-onTest-dark', 0.15),
  'fg-onTest-toggle-opacity-60': new CssDerivedColor('fg-onTest-toggle', 0.6),
  'fg-onTest-alt-dark-opacity-60': new CssDerivedColor('fg-onTest-alt-dark', 0.6),
  'fg-onTest-alt-dark-opacity-50': new CssDerivedColor('fg-onTest-alt-dark', 0.5),
  'fg-onTest-alt-dark-opacity-30': new CssDerivedColor('fg-onTest-alt-dark', 0.3),
  'fg-onTest-alt-dark-opacity-15': new CssDerivedColor('fg-onTest-alt-dark', 0.15),
  'fg-onTest-alt-toggle-opacity-60': new CssDerivedColor('fg-onTest-alt-toggle', 0.6),
  'test-alt-border-opacity-50': new CssDerivedColor('test-alt-border', 0.5),
  'border-default-opacity-70': new CssDerivedColor('border-default', 0.7),
  'utility-border-opacity-20': new CssDerivedColor('utility-border', 0.2),
  'text-inverse-opacity-15': new CssDerivedColor('text-inverse', 0.15),
  'ai-border-opacity-70': new CssDerivedColor('ai-border', 0.7),
  'ai-alt-border-opacity-70': new CssDerivedColor('ai-alt-border', 0.7),
};
