import {EnvironmentInjector, TemplateRef, ViewContainerRef, inject} from '@angular/core';
import {omit} from 'lodash';
import {take} from 'rxjs/operators';

import {Overlay} from '../overlay/overlay.service';
import {OverlayContentComponent, OverlayInstance, OverlayShowOptions} from '../overlay/overlay.types';
import {ComponentClass} from '../../types/angular';

import {AbstractDialogComponent} from './abstract-dialog.component';
import {ConfirmationDialogComponentClass} from './abstract-confirmation-dialog.component';
import {
  ConfirmationDialogOptions,
  DialogContent,
  DialogContentComponent,
  DialogInstance,
  DialogOptions,
} from './dialog.types';

function getOverlayContent(
  component: ComponentClass<DialogContentComponent>,
  viewContainerRef?: ViewContainerRef | null,
  envInjector?: EnvironmentInjector,
): ComponentClass<DialogContentComponent> | OverlayContentComponent {
  return viewContainerRef ? {component, viewContainerRef, envInjector} : component;
}

export abstract class AbstractDialogService {
  protected abstract dialogComponent: ComponentClass<AbstractDialogComponent>;
  protected abstract fullscreenDialogComponent: ComponentClass<AbstractDialogComponent>;
  protected abstract confirmationDialogComponentLoader: () => Promise<ConfirmationDialogComponentClass>;

  protected openedDialogs = new Set<DialogInstance>();
  protected overlay = inject(Overlay);

  closeAll() {
    this.openedDialogs.forEach(dialog => dialog.close());
  }

  open<TContent extends TemplateRef<any> | object>(
    content: DialogContent<TContent>,
    opts?: DialogOptions<TContent>,
    vcr?: ViewContainerRef,
    envInjector?: EnvironmentInjector,
  ): DialogInstance {
    const options = this.getOverlayOptions(content, opts);
    const overlay = this.overlay.show(getOverlayContent(this.dialogComponent, vcr, envInjector), options);

    return this.addDialogInstance(overlay, opts);
  }

  openFullscreen<TContent extends TemplateRef<any> | object>(
    content: DialogContent<TContent>,
    opts?: DialogOptions<TContent>,
    vcr?: ViewContainerRef,
    envInjector?: EnvironmentInjector,
  ): DialogInstance {
    const options = this.getOverlayOptions(content, opts);
    const overlay = this.overlay.show(getOverlayContent(this.fullscreenDialogComponent, vcr, envInjector), options);

    return this.addDialogInstance(overlay, opts);
  }

  openStepperDialog<TContent extends TemplateRef<any> | object>(
    content: DialogContent<TContent>,
    opts?: DialogOptions<TContent>,
    vcr?: ViewContainerRef,
    envInjector?: EnvironmentInjector,
  ): DialogInstance {
    const options: typeof opts = {
      ...opts,
      class: opts?.class ? `stepper-dialog ${opts.class}` : 'stepper-dialog',
      width: opts?.width || 1200,
    };

    return this.open(content, options, vcr, envInjector);
  }

  async openConfirmationDialog<TContent extends TemplateRef<any> | object>(
    title: string,
    content: string | DialogContent<TContent>,
    opts?: ConfirmationDialogOptions<TContent>,
    vcr?: ViewContainerRef,
    envInjector?: EnvironmentInjector,
  ): Promise<boolean> {
    const confirmationDialogComponent = await this.confirmationDialogComponentLoader();
    const options: DialogOptions<InstanceType<typeof confirmationDialogComponent>> = {
      width: opts?.width,
      // Inputs for the ConfirmationDialogComponent
      contentInputs: {
        header: title,
        content,
        ...omit(opts, 'width'),
      },
    };

    // In case dialog was closed via "x" button it will return `undefined` so we need to convert it to `false`.
    return this.open(confirmationDialogComponent, options, vcr, envInjector).result.then(Boolean);
  }

  private getOverlayOptions<TContent extends TemplateRef<any> | object>(
    content: DialogContent<TContent>,
    opts?: DialogOptions<TContent>,
  ): OverlayShowOptions<AbstractDialogComponent> {
    const shouldCloseOnEscape = opts?.cancellable !== false && opts?.closeOnEsc !== false && !opts?.closeConfirmation;

    return {
      props: {
        ...opts,
        content,
      },
      onEscape: shouldCloseOnEscape ? 'close' : undefined,
      themingContext: opts?.themingContext,
    };
  }

  private addDialogInstance(overlay: OverlayInstance<AbstractDialogComponent>, opts?: DialogOptions): DialogInstance {
    const dialog: DialogInstance = Object.assign(overlay, {
      close: (result: any) => {
        if (opts?.onClose?.(result) === false) {
          return false;
        }

        overlay.hide(result);

        return true;
      },
      toggleCloseButton: (visible: boolean) => {
        overlay.updateContentProps({closeButtonVisible: visible});
      },
    });

    this.openedDialogs.add(dialog);
    dialog.closed.pipe(take(1)).subscribe(() => this.openedDialogs.delete(dialog));

    return dialog;
  }
}
