import _ from 'lodash';
import {Injectable} from '@angular/core';

import {MixpanelService} from '@shared/services/mixpanel';

export type AppVersion = string;

@Injectable({
  providedIn: 'root',
})
export class AppVersionService {
  /*
   * There are more than one web-units on production which are balanced using round-robin.
   * During deployment they're being updated one by one which means some of them will report
   * a new version and some - the old one, so we need to wait some time to be sure the deployment is done.
   */
  static VERSION_STABILIZATION_DELAY = TEST && !UNIT_TEST ? 0 : 3 * 60 * 1000;

  clientVersion: AppVersion;
  serverVersion: AppVersion;
  guardUpdating = false;

  private pendingServerVersion: AppVersion;
  private initialVersionChangeTimestamp: number;

  private scheduleServerVersionCommit = _.throttle(
    () => {
      const currentVersion = this.serverVersion;

      this.serverVersion = this.pendingServerVersion;
      // Tracking total time in seconds that version stabilization took
      this.mixpanelService.track('Version sync: version stabilized', {
        from: currentVersion,
        to: this.pendingServerVersion,
        timePassed: this.timePassedSinceInitialVersionChange,
      });
    },
    AppVersionService.VERSION_STABILIZATION_DELAY,
    {leading: false, trailing: true},
  );

  constructor(private mixpanelService: MixpanelService) {}

  get outdated(): boolean {
    return this.clientVersion !== this.serverVersion;
  }

  get timePassedSinceInitialVersionChange(): number | null {
    return this.initialVersionChangeTimestamp
      ? Math.round((Date.now() - this.initialVersionChangeTimestamp) / 1000)
      : null;
  }

  set(version: AppVersion) {
    this.clientVersion = this.serverVersion = this.pendingServerVersion = version;
  }

  updateServerVersion(version: AppVersion) {
    this.pendingServerVersion = version;

    if (this.pendingServerVersion === this.serverVersion) {
      this.scheduleServerVersionCommit.cancel();
    } else {
      if (!this.initialVersionChangeTimestamp) {
        this.initialVersionChangeTimestamp = Date.now();
      }

      this.scheduleServerVersionCommit();
    }
  }
}
