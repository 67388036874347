import _ from 'lodash';
import {Injectable} from '@angular/core';

import {RecipeStep, Step, StepKeyword} from '../types';

export enum WalkInstruction {
  SKIP_CHILDREN,
  STOP,
}

const STEPS_WITH_KEYWORD_AS_PROVIDER = new Set<StepKeyword>(['foreach', 'repeat', 'catch']);

@Injectable({
  providedIn: 'root',
})
export class StepTraverser {
  walk<T extends Step<T>>(step: T, callback: (step: T) => WalkInstruction | void): WalkInstruction.STOP | void {
    let instruction = callback(step);

    if (instruction === WalkInstruction.STOP) {
      return WalkInstruction.STOP;
    }

    const {block} = step;

    if (!block || !block.length || instruction === WalkInstruction.SKIP_CHILDREN) {
      return;
    }

    for (let i = 0, len = block.length; i < len; i++) {
      instruction = this.walk(block[i], callback);

      if (instruction === WalkInstruction.STOP) {
        return WalkInstruction.STOP;
      }
    }
  }

  walkAll<T extends Step<T>>(
    steps: T[] = [],
    callback: (step: T) => WalkInstruction | void,
  ): WalkInstruction.STOP | void {
    steps.forEach(step => {
      const instruction = this.walk(step, callback);

      if (instruction === WalkInstruction.STOP) {
        return WalkInstruction.STOP;
      }
    });
  }

  findStepByNumber<TStep extends Step<TStep>>(rootStep: TStep, stepNumber: TStep['number']): TStep | undefined {
    return this.findStep(rootStep, step => step.number === stepNumber);
  }

  findParentBlock(
    stepNumber: RecipeStep['number'],
    parentBlock: RecipeStep[],
    step: RecipeStep,
  ): RecipeStep[] | undefined {
    if (step.number === stepNumber) {
      return parentBlock;
    } else {
      const childBlock = step.block || [];
      let block: RecipeStep[] | undefined;

      _.forEach(childBlock, childStep => {
        block = this.findParentBlock(stepNumber, childBlock, childStep);

        if (block) {
          // stop loop
          return false;
        }
      });

      return block;
    }
  }

  findStep<TStep extends Step<TStep>>(rootStep: TStep, callback: (step: TStep) => boolean): TStep | undefined {
    let foundStep: TStep | undefined;

    this.walk(rootStep, step => {
      if (callback(step)) {
        foundStep = step;

        return WalkInstruction.STOP;
      }
    });

    return foundStep;
  }

  findParentStep<TStep extends Step<TStep>>(rootStep: TStep, childStep: TStep): TStep | undefined {
    return this.findStep(rootStep, step => Boolean(step.block?.includes(childStep)));
  }

  findStepByProps(rootStep: RecipeStep, props: Partial<RecipeStep>): RecipeStep | undefined {
    return this.findStep(rootStep, step => _.isMatch(step, props));
  }

  findStepByAlias(
    rootStep: RecipeStep,
    alias: RecipeStep['as'],
    provider: RecipeStep['provider'],
  ): RecipeStep | undefined {
    const providerKey = STEPS_WITH_KEYWORD_AS_PROVIDER.has(provider as StepKeyword) ? 'keyword' : 'provider';

    return this.findStep(rootStep, step => step[providerKey] === provider && step.as === alias);
  }

  findMatchingStep(rootStep: RecipeStep, props: Partial<RecipeStep>): RecipeStep | undefined {
    const matches = _.matches(props);

    return this.findStep(rootStep, step => matches(step));
  }

  findSibling(rootStep: RecipeStep, step: RecipeStep, which: 'prev' | 'next'): RecipeStep | null {
    const parentStep = this.findParentStep(rootStep, step);
    const stepIndex = parentStep?.block ? parentStep.block.indexOf(step) : -1;

    if (stepIndex === -1 || !parentStep?.block) {
      return null;
    }

    const index = which === 'next' ? stepIndex + 1 : stepIndex - 1;

    return parentStep.block[index] || null;
  }

  getStepChildren(rootStep: RecipeStep): RecipeStep[] {
    const children: RecipeStep[] = [];

    this.walk(rootStep, step => {
      if (step !== rootStep) {
        children.push(step);
      }
    });

    return children;
  }

  /**
   * Returns an array of step numbers (traversing child step blocks).
   *
   * @param step - Starting step that will be traversed.
   * @param [stepNumbers=[]] - An array to add step numbers to.
   * @returns {array}
   */
  getStepNumbers(rootStep: RecipeStep, stepNumbers: number[] = []): number[] {
    this.walk(rootStep, step => {
      if (step.number) {
        stepNumbers.push(step.number);
      }
    });

    return stepNumbers;
  }

  getStepNumbersCount(rootStep: RecipeStep, countTrigger = false): number {
    let count = 0;

    this.walk(rootStep, step => {
      if (step.number || countTrigger) {
        count++;
      }
    });

    return count;
  }

  hasMultipleDescendants(rootStep: RecipeStep): boolean {
    if (!rootStep.block?.length) {
      return false;
    }

    if (rootStep.block.length > 1) {
      return true;
    }

    return Boolean(rootStep.block[0].block?.length);
  }
}
