import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {NgClass} from '@angular/common';

export type FlashType = 'error' | 'warning' | 'success' | 'info' | 'notice';

@Component({
  selector: 'w-flash',
  templateUrl: './flash.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrl: './flash.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class FlashComponent {
  @Input() type: FlashType = 'success';
  @Input() header?: string;
  @Input() showCloseIcon = false;

  @Output() close = new EventEmitter();

  hidden = false;

  get classes(): NgClass['ngClass'] {
    return {
      [`flash_${this.type}`]: this.type,
    };
  }

  handleCloseClick() {
    if (this.close.observers.length === 0) {
      this.hidden = true;
    } else {
      this.close.emit();
    }
  }
}
